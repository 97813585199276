import { Component } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConexionApiService } from './conexion-api.service';
import { UpdateService } from './services/update.service';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  telefono:any = environment.contacto.wsp;
  texto_contacto:any = environment.contacto.texto;
  constructor(
    private platform: Platform,
    private device: DeviceDetectorService,
    private nav: NavController,
    public api: ConexionApiService,
    public sw: UpdateService,
    public router: Router,
    private locationStrategy : LocationStrategy
  ) {
    /*
      <div id="overlay-portrait">
    <div class="content">
      <ion-icon name="phone-portrait"></ion-icon>
      <h2>Su celular debe permanecer en posición vertical</h2>
    </div>
  </div>
    */

    // block back button 
    this.preventBackButton();

    //  evitar install banner 
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
    });

    this.sw.checkForUpdates();
    // con esta funcion me aseguro de verificar que esté
    //  en un mobile durante toda la app
    if (this.device.isDesktop()) {
      this.nav.navigateRoot('/browser');
    } else {
      this.initializeApp();
    }
    // debug viewport

    let overlay:HTMLElement = document.createElement('div');

    //this.api.presentToast("DPR:" +window.devicePixelRatio + " RES:" + window.innerWidth + "x" + window.innerHeight, 5);
  }


  public ayudaWhatsappButton(){
    this.api.btnWspAyuda();
  }

  public preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }

  initializeApp() {
    // show app... don't touch
    this.platform.ready().then(() => {
      console.log("ready");
    });
  }
}
