// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/// echo "autoinspeccion" | openssl enc -aes-256-cbc -md md5 -pass pass:"totall^Cecretpasswordlol" -e -base64
export const environment = {
  _ssp: 'M#0AygZi36C9*EcPxNATUsB^V9WlZmUQJ^FrrJ$tUvvBQAnnftWS@DKfhmcrQ!*z',
  api: {
    "ull_": "https://testing.rutayasistenciaclub.cl/app/views/mw_autoinspeccion/",///"https://ws.rutayasistenciaclub.cl/app/views/mw_autoinspeccion/",
    "uss_": 'U2FsdGVkX194OQ6Fhb7bFqabdNcyPXViWJLrJ8mBs0k=',
    "pss_": 'U2FsdGVkX19QcQIhfqibUohxb0wgk2k3cztQWMyRfPI=',
  },
  production: false,
  gtag_mode: true,
  contacto : {
    wsp: "+56962797076",
    texto : "Hola! Necesito ayuda"
  },

  // codigo para desarrollo
  gtagcode: 'GTM-P9GL26H',
  apiurl: "https://testing.rutayasistenciaclub.cl/app/views/mw_autoinspeccion/"//'https://ws.rutayasistenciaclub.cl/app/views/mw_autoinspeccion/',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
